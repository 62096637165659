<template lang="pug">
.subscribe__payment
  template(v-if="paymentMethod==='card'")
    template(v-if="showReCAPTCHAV2")
      .subscribe__form-group
        .recaptcha-v2
          label.error
            | Please complete the reCAPTCHA below to process your payment again.
          VueRecaptchaV2(
            :sitekey="reCAPTCHAV2SiteKey"
            :loadRecaptchaScript="true"
            ref="recaptchaV2"
            @verify="onReCAPTCHAV2Verified"
            @expired="onReCAPTCHAV2Expired"
          )
    .subscribe__form-group
      label.cc-info(
        for="card-number"
      ) Card Number
      #card-number.subscribe__form-field
        template(v-if="cardImage === ''")
          i.icon.icon-card
        template(v-else)
          #card-image(
            :class="[cardImage]"
          )
    .subscribe__form-group.split
      .subscribe__form-group
        label.cc-info(
          for="expiration-date"
        ) Expiration Date (MM/YY)
        #card-expiration-date.subscribe__form-field
      .subscribe__form-group
        label.cc-info(
          for="cvv"
        ) CVV ({{cvvSize}} digits)
        #card-cvv.subscribe__form-field
    p.terms
      | By adding this credit card, you are agreeing to the&nbsp;
      a(:href="termsUrl" target="_blank") Terms & Conditions.
    p.terms
      | This site is protected by reCAPTCHA and the Google&nbsp;
      a(href="https://policies.google.com/privacy" target="_blank") Privacy Policy
      | &nbsp;and&nbsp;
      a(href="https://policies.google.com/terms" target="_blank") Terms of Service
      | &nbsp;apply.
    BaseButton.btn-make-payment(
      isFullWidth=true
      isPrimary=true
      isTall=true
      :disabled="!isCCFormValid || (showReCAPTCHAV2 && isEmpty(reCAPTCHAV2Token))"
      @click="makePayment"
    ) Make Payment
  template(v-else-if="paymentMethod==='paypal'")
    p.terms
      | By authorizing with PayPal, you are agreeing to the&nbsp;
      a(:href="termsUrl" target="_blank") Terms & Conditions.
    #paypal-button
      BasePreloader(
        v-if="paypalIsLoading"
        :width="30"
      )
</template>

<script>
import { mapMutations } from 'vuex';

import braintree from 'braintree-web';
import paypal from 'paypal-checkout';
import VueRecaptchaV2 from 'vue-recaptcha';
import Api from '@/services/api/Api';
import UserApi from '@/services/api/UserApi';
import { homeUrl, isProduction, iframeParentURL } from '@/assets/scripts/variables';
import { isEmpty } from '@/assets/scripts/utilities';
import helperMixins from '@/components/mixins/helper-mixins';


export default {
  name: 'SubscribeForm',
  mixins: [
    helperMixins,
  ],
  /* eslint-disable */
  data() {
    return {
      termsUrl: `${homeUrl()}/terms-conditions`,
      cvvSize: '3',
      cardImage: '',
      isCCFormValid: false,
      hostedFieldInstance: null,
      nonce: '',
      error: '',
      reCAPTCHAV2SiteKey: process.env.VUE_APP_RECAPTCHA_V2_SITE_KEY,
      reCAPTCHAV2Token: '',
      showReCAPTCHAV2: false,
      paymentUnsuccessfulReCAPTCHAText: 'Payment Unsuccessful (reCAPTCHA Issue) - No payment has been charged to you. Please contact us at help@offeo.com',
      paymentUnsuccessfulText: 'Payment Unsuccessful - No payment has been charged to you. Please contact us at help@offeo.com',
      paypalIsLoading: false,
      deviceData: '',
    };
  },
  /* eslint-enable */
  components: {
    VueRecaptchaV2,
  },
  props: {
    plan: { type: String, default: 'offeo-premium-monthly' },
    paymentMethod: { type: String, default: '' },
    promoCode: { type: String, default: '' },
  },
  computed: {
    isPayingByCard() {
      return this.paymentMethod === 'card';
    },
    isPayingByPayPal() {
      return this.paymentMethod === 'paypal';
    },
  },
  methods: {
    ...mapMutations('userData', [
      'setUserLevel',
    ]),
    makePayment() {
      this.$emit('payment-loading', true);

      if (isEmpty(this.reCAPTCHAV2Token)) {
        this.recaptchaV3();
      } else {
        this.validateReCaptcha('v2', this.reCAPTCHAV2Token);
      }
    },
    async recaptchaV3() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('checkout');

      this.validateReCaptcha('v3', token);
    },
    validateReCaptcha(version, token) {
      if (version === 'v2') {
        this.reCAPTCHAV2Token = '';
        this.$refs.recaptchaV2.reset();
      }
      // Validate token server side
      const recaptchaParams = {
        g_recaptcha_token: token,
        version,
      };
      Api.validateReCaptcha(recaptchaParams)
        .then((response) => {
          if (response.data.success) {
            if (version === 'v3') {
              if (response.data.score > process.env.VUE_APP_RECAPTCHA_CHECKOUT_THRESHOLD) {
                if (this.isPayingByCard) {
                  this.payWithCard();
                }
              } else {
                this.showReCAPTCHAV2 = true;
                this.$emit('payment-loading', false);
              }
            } else if (version === 'v2') {
              if (this.isPayingByCard) {
                this.payWithCard();
              }
            }
          } else {
            this.alertError('', this.paymentUnsuccessfulReCAPTCHAText, 5000, 'TopCenterNotif');
            this.$emit('payment-loading', false);
            this.showErrorMessage(this.paymentUnsuccessfulReCAPTCHAText);
          }
        }).catch((error) => {
          console.log(error);
          this.alertError('', this.paymentUnsuccessfulReCAPTCHAText, 5000, 'TopCenterNotif');
          this.$emit('payment-loading', false);
          this.showErrorMessage(this.paymentUnsuccessfulReCAPTCHAText);
        });
    },
    onReCAPTCHAV2Verified(recaptchaToken) {
      this.reCAPTCHAV2Token = recaptchaToken;
    },
    onReCAPTCHAV2Expired() {
      this.$refs.recaptchaV2.reset();
      this.alertError('', this.paymentUnsuccessfulReCAPTCHAText, 5000, 'TopCenterNotif');
      this.showErrorMessage(this.paymentUnsuccessfulReCAPTCHAText);
    },
    onReCAPTCHAV2Error() {
      this.$refs.recaptchaV2.reset();
      this.alertError('', this.paymentUnsuccessfulReCAPTCHAText, 5000, 'TopCenterNotif');
      this.showErrorMessage(this.paymentUnsuccessfulReCAPTCHAText);
    },
    payWithCard() {
      if (this.hostedFieldInstance && this.isCCFormValid) {
        this.error = '';
        this.nonce = '';
        this.hostedFieldInstance.tokenize().then((payload) => {
          this.nonce = payload.nonce;

          // Subscribe via AJAX
          this.subscribe();
        }).catch((err) => {
          console.error(err);
          this.error = err.message;
          this.$emit('payment-loading', false);
        });
      } else {
        this.$emit('payment-loading', false);
        let msg = 'Payment Unsuccessful - No payment has been charged to you.';
        if (!this.isCCFormValid) {
          msg = 'Payment information is not valid';
        }
        this.alertError('', msg, 5000, 'TopCenterNotif');
        this.showErrorMessage(msg);
      }
    },
    setupBraintreePayment() {
      if (this.isPayingByPayPal) this.paypalIsLoading = true;
      else this.paypalIsLoading = false;

      braintree.client.create({
        authorization: `${process.env.VUE_APP_BRAINTREE_TOKEN}`,
      })
        .then((clientInstance) => {
          const options = {
            client: clientInstance,
            styles: {
              input: {
                'font-family': 'Work Sans, Arial, Helvetica, sans-serif',
                color: '#0A1F44',
                'letter-spacing': '1px',
              },
              'input.invalid': {
                color: '#F03D3D',
              },
              'input.valid': {
                color: '#0BB07B',
              },
            },
            fields: {
              number: {
                selector: '#card-number',
                placeholder: 'xxxx xxxx xxxx xxxx',
              },
              cvv: {
                selector: '#card-cvv',
                placeholder: '(eg: 123)',
              },
              expirationDate: {
                selector: '#card-expiration-date',
                placeholder: 'MM/YY',
              },
            },
          };

          const braintreeCreate = [];
          if (this.isPayingByCard) {
            braintreeCreate.push(braintree.hostedFields.create(options));
          } else if (this.isPayingByPayPal) {
            braintreeCreate.push(braintree.paypalCheckout.create({ client: clientInstance }));
          }

          // Collect device data for advanced fraud protection
          braintree.dataCollector.create({
            client: clientInstance,
            kount: true,
          }).then((dataCollectorInstance) => {
            this.deviceData = dataCollectorInstance.deviceData;
          }).catch((err) => {
            // Handle error in data collector creation
            console.error('error', err);
          });

          return Promise.all(braintreeCreate);
        })
        .then((instances) => {
          if (this.isPayingByCard) {
            const hostedFieldInstance = instances[0];

            hostedFieldInstance.on('validityChange', (event) => {
              // Check if all fields are valid, then show submit button
              this.isCCFormValid = Object.keys(event.fields).every(key => event.fields[key].isValid);
            });

            hostedFieldInstance.on('empty', () => {
              this.cardImage = '';
            });

            hostedFieldInstance.on('cardTypeChange', (event) => {
              // Change card bg based on card type
              if (event.cards.length === 1) {
                this.cardImage = event.cards[0].type;

                // Change CVV length for AMEX
                if (event.cards[0].code.size === 4) {
                  this.cvvSize = '3 or 4';
                  hostedFieldInstance.setAttribute({
                    field: 'cvv',
                    attribute: 'placeholder',
                    value: '(eg: 123 or 1234)',
                  });
                } else {
                  this.cvvSize = '3';
                  hostedFieldInstance.setAttribute({
                    field: 'cvv',
                    attribute: 'placeholder',
                    value: '(eg: 123)',
                  });
                }
              } else {
                this.cardImage = '';
                this.cvvSize = '3';
                hostedFieldInstance.setAttribute({
                  field: 'cvv',
                  attribute: 'placeholder',
                  value: '(eg: 123)',
                });
              }
            });

            this.hostedFieldInstance = hostedFieldInstance;
          } else if (this.isPayingByPayPal) {
            if (!document.getElementById('paypal-button')) return;
            const paypalCheckoutInstance = instances[0];
            const self = this;

            // Setup PayPal Button
            paypal.Button.render({
              env: process.env.VUE_APP_BRAINTREE_ENV,
              style: {
                size: 'responsive',
                shape: 'pill',
                color: 'blue',
                label: 'paypal',
                tagline: false,
              },
              payment() {
                self.$emit('payment-loading', true);
                return paypalCheckoutInstance.createPayment({
                  flow: 'vault',
                });
              },
              // eslint-disable-next-line
              onAuthorize(data, options) {
                return paypalCheckoutInstance.tokenizePayment(data).then((payload) => {
                  self.error = '';
                  self.nonce = payload.nonce;

                  // Subscribe via AJAX
                  self.subscribe();
                });
              },
              onCancel(data) {
                self.$emit('payment-loading', false);
                const message = 'Payment Cancelled - No payment has been charged to you.';
                self.alertWarn('', message, 5000, 'TopCenterNotif');
                console.log('payment cancelled', JSON.stringify(data, 0, 2));
                self.showWarnMessage(message);
              },
              onError(err) {
                self.$emit('payment-loading', false);
                const message = 'Payment Unsuccessful - No payment has been charged to you.';
                self.alertError('', message, 5000, 'TopCenterNotif');
                console.error('error', err);
                self.showErrorMessage(message);
              },
            }, '#paypal-button');
          }
        })
        .then(() => {
          this.paypalIsLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    subscribe() {
      const subscribeParams = {
        plan: this.plan,
        subscribe_nonce: this.nonce,
        payment_method: this.paymentMethod,
        device_data: this.deviceData,
      };

      if (!isEmpty(this.promoCode)) {
        subscribeParams.promo_code = this.promoCode;
      }

      Api.subscribe(subscribeParams)
        .then((response) => {
          if (response.data.success) {
            const hasActiveSubscription = (typeof response.data.has_active_subscription !== 'undefined' && response.data.has_active_subscription);
            // Update tracking
            if (isProduction() && !hasActiveSubscription) {
              if (typeof window.fbq !== 'undefined') {
                window.fbq('track', 'Purchase', { currency: 'USD', value: response.data.amount });
              }
            }

            // Get updated user details
            UserApi.userDetails()
              .then((userResponse) => {
                const subscriptionLevel = userResponse.data.subscription_level;
                this.setUserLevel(subscriptionLevel);

                if (!hasActiveSubscription) {
                  // eslint-disable-next-line
                  const successMessage = 'You have successfully subscribed to OFFEO Premium! You should receive an email with your subscription confirmation shortly.';
                  this.alertSuccess('', successMessage, 5000, 'TopCenterNotif');

                  window.parent.postMessage(
                    {
                      paymentSuccess: true,
                      message: successMessage,
                      subscriptionLevel,
                    },
                    iframeParentURL(),
                  );
                }
              });
          } else {
            this.$emit('payment-loading', false);
            this.alertError('', this.paymentUnsuccessfulText, 5000, 'TopCenterNotif');
            this.showReCAPTCHAV2 = false;
            this.showErrorMessage(this.paymentUnsuccessfulText);
          }
        }).catch((error) => {
          console.log(error);
          this.$emit('payment-loading', false);
          this.alertError('', this.paymentUnsuccessfulText, 5000, 'TopCenterNotif');
          this.showReCAPTCHAV2 = false;
          this.showErrorMessage(this.paymentUnsuccessfulText);
        });
    },
    showErrorMessage(message) {
      // this is for other site that's using iframe
      window.parent.postMessage(
        {
          type: 'error',
          message,
        },
        iframeParentURL(),
      );
    },
    showWarnMessage(message) {
      // this is for other site that's using iframe
      window.parent.postMessage(
        {
          type: 'warn',
          message,
        },
        iframeParentURL(),
      );
    },
  },
  mounted() {
    this.setupBraintreePayment();
  },
  watch: {
    paymentMethod: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          console.log('new val', newVal);
          // need to manually remove the paypal button inserted by paypal or it will move to the card tab
          if (newVal === 'card'
            && document.getElementsByClassName('paypal-button').length) {
            document.getElementsByClassName('paypal-button')[0].remove();
            const elements = document.getElementsByClassName('braintree-dispatch-frame');
            while (elements.length > 0) {
              elements[0].parentNode.removeChild(elements[0]);
            }
          }

          if (typeof oldVal !== 'undefined' && oldVal !== newVal) {
            this.setupBraintreePayment();
          }
        });
      },
    },
  },
};
</script>

<style lang="scss">
.subscribe__payment {
  .subscribe__form-group {
    position: relative;
    padding-top: 0;
    width: 100%;
    height: auto;
    top: unset;
    margin-bottom: 0;
    margin-top: 20px;

    &.split {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0;

      .subscribe__form-group:first-child {
        margin-right: 20px;
        width: calc(100% * 1.5);
      }
    }

    .subscribe__form-field {
      width: 100%;
      padding: 15px;
      border: 1px solid $borderGrey;
      height: 50px;
      border-radius: $componentBorderRadius;
      background: transparent;

      font-family: "Work Sans", Arial, Helvetica, sans-serif;
      color: $textBlack;
      font-size: 0.875em;

      &:focus,
      &.braintree-hosted-fields-focused {
        outline: none;
        border: 1px solid $blue;
      }
    }
  }

  label {
    display: block;
    color: $inputLabel;
    font-size: 0.85rem;
    font-weight: 500;
    margin-bottom: 5px;

    &.error {
      color: $red;
    }
  }

  #card-number {
    position: relative;

    .icon-card {
      position: absolute;
      right: 10px;
    }

    #card-image {
      position: absolute;
      right: 5px;
      width: 44px;
      height: 28px;
      background-size: auto 12px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: 26px 3px;

      &.visa,
      &.master-card,
      &.american-express,
      &.discover,
      &.maestro,
      &.jcb,
      &.diners-club {
        bottom: calc(calc(100% - 28px) / 2);
        background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/346994/card_sprite.png);
        background-size: 86px 458px;
        background-position: -100px 0;
      }
      &.visa {
        background-position: 0 -398px;
      }
      &.master-card {
        background-position: 0 -281px;
      }
      &.american-express {
        right: 10px;
        background-position: 0 -370px;
      }
      &.discover {
        background-position: 0 -163px;
      }
      &.maestro {
        background-position: 0 -251px;
      }
      &.jcb {
        background-position: 0 -221px;
      }
      &.diners-club {
        background-position: 0 -133px;
      }
    }
  }

  .btn-make-payment {
    margin-top: 10px;
  }

  #paypal-button {
    width: 50%;
    margin: 20px auto;
    min-height: 25px;
  }
}

.subscribe .content .subscribe__content p.terms {
  font-weight: 500;
  font-size: 0.75rem;
  margin-top: 15px;

  a {
    color: $darkGrey;
  }
}
</style>
