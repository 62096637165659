<template lang="pug">
.account-controller
  BasePreloader(v-if='isLoading && !isShowSubscriptionModal')
  template(v-else)
    iframe.login-iframe(
      v-show='!isLoggedIn',
      ref='loginIframe',
      :src='loginUrl',
      width='600',
      height='700'
    )

    //-
      iframe.subscribe-iframe(
        v-if='(isLoggedIn && isFreeUser) || isShowSubscriptionModal',
        ref='subscribeIframe',
        :src='subscribeUrl',
        width='600',
        height='700'
      )
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import UserApi from '@/services/api/UserApi';
import prodScriptsMixin from '@/components/mixins/prod-scripts-mixins';
import helperMixin from '@/components/mixins/helper-mixins';

export default {
  name: 'AccountController',
  mixins: [helperMixin, prodScriptsMixin],
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState([
      'isLoggedIn',
      'isShowSubscriptionModal',
    ]),
    ...mapState('account', ['loginModalVisible']),
    ...mapGetters('userData', ['isFreeUser']),
    publicUrl() {
      return process.env.VUE_APP_PUBLIC_URL;
    },
    dashboardUrl() {
      return process.env.VUE_APP_DASHBOARD_URL;
    },
    canvasUrl() {
      return process.env.VUE_APP_CANVAS_URL;
    },
    loginUrl() {
      return `${this.publicUrl}/login`;
    },
    subscribeUrl() {
      return `${this.dashboardUrl}/get-premium`;
    },
    logOutUrl() {
      return `${this.canvasUrl}/logout`;
    },
  },
  mounted() {
    console.log('mounted');
    // from multiple login overlay. if it's triggering login modal visible, set loading to false automatically
    if (this.loginModalVisible) {
      this.isLoading = false;
      return;
    }

    if (this.$route.query.mode === 'lite') {
      this.setLiteMode();
    }

    UserApi.userDetails()
      .then((response) => {
        console.log('response.data.results', response.data.results.user);
        this.updateUserDetails(response.data.results);
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
        this.processError();
      });

    this.addIFrameListener();
  },
  methods: {
    ...mapMutations([
      'setIsLoggedIn',
      'setShowUpgradeSubscription',
      'setIsModalVisible',
      'setSignUpPage',
      'setShowMultipleLoginWarning',
      'setShowSubscriptionModal',
    ]),
    ...mapMutations(['setLiteMode']),
    ...mapMutations('userData', [
      'setUserLevel',
      'setUserRole',
      'setName',
      'setUsername',
      'setProfilePicture',
      'setUserId',
      'setRemoveBgCredits',
      'setUsedNewPricing',
      'setStorageSpaceLeft',
      'setRequiresSubscription',
      'setUserData',
    ]),
    ...mapMutations('account', [
      'hideLoginModal',
      'setIsAuthenticating',
      'setAuthenticated',
    ]),
    setLocalAxiosAuthorization() {
      // if (
      //   process.env.VUE_APP_ENV === 'local'
      //   && window.location.hostname === 'localhost'
      // ) {
      //   // set access_token_cookie
      //   const localAccessCookie = `${process.env.VUE_APP_OAUTH_TOKEN_COOKIE}_${process.env.VUE_APP_ENV}`;
      //   const accessToken = VueCookies.get(localAccessCookie);
      //   console.log('accessToken', accessToken)
      //   if (accessToken) {
      //     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      //   }
      // }
    },
    addIFrameListener() {
      window.addEventListener('message', (event) => {
        // console.log('message', event.origin, event.data);
        // IMPORTANT: check the origin of the data!
        if (event.origin.startsWith(this.publicUrl) || event.origin.startsWith(this.dashboardUrl)) {
          // The data was sent from your site.
          // Data sent with postMessage is stored in event.data
          console.log('message', event.origin, event.data, event.data.results);

          const { results } = event.data;

          if (event.data.isLoggedIn) {
            this.hideLoginModal();
            this.setLocalAxiosAuthorization();
            this.iframeIsShown = false;
            this.setIsAuthenticating(false);
            this.setShowMultipleLoginWarning(false);
            this.setIsLoggedIn(true);

            const userDetails = results.user;
            userDetails.remove_bg_credits = results.remove_bg_credits;
            userDetails.storage_space_left = results.storage_space_left;
            userDetails.used_new_pricing = results.used_new_pricing;
            this.updateUserDetails(userDetails);
            this.setExternalScripts();
            this.isLoading = false;
          }

          if (event.data.paymentSuccess) {
            this.setUserLevel(event.data.subscriptionLevel);
            this.alertSuccess('', event.data.message, 5000, 'TopCenterNotif');
            this.isLoading = false;
          }

          if (event.data.type === 'error') {
            this.alertError('', event.data.message, 5000, 'TopCenterNotif');
          }

          if (event.data.type === 'warn') {
            this.alertWarn('', event.data.message, 5000, 'TopCenterNotif');
          }

          if (event.data.close) {
            this.setShowSubscriptionModal(false);
          }

          if (event.data.logOut) {
            // log out stuff is handled in the iframe
            window.location.href = this.publicUrl;
          }
        }
      });
    },
    updateUserDetails(data) {
      const userLevel = data.subscription_level;
      const userRole = data.role_id;
      const userId = data.id;
      const userEmail = data.email;
      const profilePicture = data.avatar;
      const createdAt = data.created_at;
      const loginToken = data.login_token;
      const storageSpaceLeft = data.storage_space_left;
      const removeBgCredits = data.remove_bg_credits;
      const usedNewPricing = data.used_new_pricing;
      const { name, username } = data;

      // console.log('AccountController.vue@updateUserDetails', data);

      this.setUserData({
        userLevel,
        userRole,
        name,
        username,
        profilePicture,
        userId,
        storageSpaceLeft,
        userEmail,
        removeBgCredits,
        usedNewPricing,
        createdAt,
        loginToken,
      });

      this.setIsLoggedIn(true);
    },
    processError() {
      // if (error.response.status === 401) {
      //   window.location.href = `${process.env.VUE_APP_PUBLIC_URL}/register`;
      //   return;
      // }

      this.setIsLoggedIn(false);
    },
  },
};
</script>

<style lang="scss">
.account-controller {
  .preloader {
    background: #fff;
    width: 100%;
    height: 100vh;
    z-index: 99;
    position: relative;
  }
}

.subscribe-iframe,
.login-iframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
</style>
