<template lang="pug">
CanvasModal.subscribe(
  isShow=true
  :extraClass="extraClass"
  @closeOverlay="closeModal"
  noPadding=true
  :hideCloseButton="!isCloseable || step == 1"
)
  button.btn-upgrade-next-time(
    v-if="step === 1 && showUpgradeNextTimeButton"
    @click="upgradeNextTime()"
  )
    span Upgrade next time

  .subscribe__content
    template(v-if="step === 1")
      .subscribe__content--step-1
        .subscribe__title
          | Everything you
          br
          | need to create a
          br
          | professional video
        p
          | The all-in-one video creation solution.
          br
          | Full creative control & customization.
        p
          | What's included:
        ul.upgrade__list
          li.upgrade__item
            i.icon.icon-checked
            | Thousands of curated design templates
          li.upgrade__item
            i.icon.icon-checked
            | Unlimited High Resolution Video Downloads
          li.upgrade__item
            i.icon.icon-checked
            | Unlimited Brand Fonts and Colors
          li.upgrade__item
            i.icon.icon-checked
            | No Watermarks
          li.upgrade__item
            i.icon.icon-checked
            | Reseller License Rights
        p
          | Includes even more features such as extended duration and custom resizing.
        BaseButton.btn-next(
          isFullWidth=true
          isPrimary=true
          isTall=true
          iconAfter="icon-right-arrow-long"
          @click="step = 2"
        )
          | Upgrade to Premium

    template(v-if="step === 2")
      .subscribe__content--step-2
        template(v-if="isLoading")
          .loading
            img.preloader__image(
              src="@/assets/images/preloader/offeo_preloader_2.svg"
            )
            | Retrieving plan...
        template(v-else)
          .subscribe__title
            | Upgrade to<br/>OFFEO Premium
          .plan-price
            span.plan-price__label Monthly
            BaseSwitch.plan-price__switch(
              name="plan-price"
              :isChecked="selectedPlanFrequency === 'annually'"
              @change="togglePlan"
            )
            span.plan-price__label Annually
            .plan-price__savings
              img(src="@/assets/images/icons/save-arrow.png")
              span Save 26%
          .plan__wrapper
            .plan__header
              p.plan__header--title.plan-type {{ planType }}
            .plan__body
              p
                | Plan includes:
              ul.plan__list
                template(v-if="selectedPlanFrequency==='monthly'")
                  li.plan__item
                    .plan__item--wrapper
                      .plan__item--name
                        i.icon.icon-checked
                        | Access all Premium
                        br
                        | features
                      .plan__item--price
                        | $19/month
                template(v-if="selectedPlanFrequency==='annually'")
                  li.plan__item
                    .plan__item--wrapper
                      .plan__item--name
                        i.icon.icon-checked
                        | Access all Premium
                        br
                        | features
                      .plan__item--price
                        | $149/year
                        br
                        | ($12.40/month)
            .plan__footer
              .plan__item--wrapper.discount(
                  v-if="savings > 0"
                )
                .plan__item--name
                  span.code
                    | Discount applied
                .plan__item--price
                  | -$
                  span.total-discount {{ savings }}
              .plan__item--wrapper.bold
                .plan__item--name
                  | Due today
                .plan__item--price
                  | $
                  span.due-today {{ billedToday }}
              .smaller.lighter.t--center
                | Prices are shown in USD
          .plan__promo-code
            label(
              for="promo-code"
              @click="showPromoCodeInput = !showPromoCodeInput"
            ) Add Promo Code
            transition(
              name="slide-vertical"
            )
              .promo-code__wrapper(
                v-if="showPromoCodeInput"
              )
                BaseInputField.promo-code(
                  v-model="promoCode"
                  placeholder="Have a promo code?"
                  isTall=true
                  :isAlert="!isPromoCodeValid"
                  :isSuccess="isPromoCodeApplied"
                  :msg="promoCodeMsg"
                  @keyup.enter="applyPromoCode"
                  @input="isPromoCodeValid = true"
                )
                .promo-code__buttons
                  BaseButton.btn-remove-promo-code(
                    v-if="promoCode !== ''"
                    isText=true
                    @click="promoCode = ''"
                  )
                    i.icon.icon-cancel
                  BaseButton.btn-apply-promo-code(
                    :disabled="isPromoCodeLoading"
                    :isLoading="isPromoCodeLoading"
                    @click="applyPromoCode"
                  ) Apply
          .plan__payment-methods
            BaseButton.btn-card(
              isFullWidth=true
              isSuccess=true
              isTall=true
              iconAfter="icon-card"
              @click="goToPayment('card')"
            ) Pay by Card
            BaseButton.btn-paypal(
              isFullWidth=true
              isPrimary=true
              isTall=true
              iconAfter="icon-paypal"
              @click="goToPayment('paypal')"
            ) Pay by PayPal
    template(v-if="step === 3")
      .subscribe__content--step-3
        template(v-if="isMakingPayment")
          .loading
            img.preloader__image(
              src="@/assets/images/preloader/offeo_preloader_2.svg"
            )
            | Making payment...
        div(
          :class="[{ 'is-hidden': isMakingPayment }]"
        )
          BaseButton.btn-back(
            isTall=false
            isText=true
            @click="goToPlanReview"
          )
            i.icon.icon-left-arrow
            | {{ planType }}
          .subscribe__subtitle Payment Information
          SubscribeForm(
            :paymentMethod="paymentMethod"
            :plan="selectedPlan"
            :promoCode="promoCode"
            @payment-loading="showPaymentLoading"
          )
          .subscribe__footer-payment
            i.icon.icon-lock
            | Your details are 128-bit SSL encrypted, which means they're 100% safe with us.
  .subscribe__content.example
    template(v-if="!isCloseable")
      BaseButton.btn-logout(
        isTall=false
        isText=true
        isDanger=true
        :href="logoutLink"
      )
        span Logout
    .example__wrapper
      img(
        class="example__background"
        src="@/assets/images/backgrounds/premium-example.png"
      )
      img(
        class="example__gif"
        src="@/assets/images/backgrounds/premium-example.gif"
      )

</template>

<script>
import { mapMutations, mapState } from 'vuex';

import Api from '@/services/api/Api';

import SubscribeForm from '@/components/ProjectCanvas/PageOverlay/SubscribeForm.vue';

import { showDecimalIfNeeded, isEmpty } from '@/assets/scripts/utilities';

export default {
  name: 'Subscribe',
  props: {
    isCloseable: { type: Boolean, default: true },
    showUpgradeNextTimeButton: { type: Boolean, default: true },
  },
  data() {
    return {
      step: 1,
      paymentMethod: '',
      selectedPlanFrequency: 'monthly',
      billedToday: 1,
      billedAs: 0.0,
      oldAmount: 0.0,
      newAmount: 0.0,
      savings: 0.0,
      savingsPercentage: 0,
      hasAffiliateDiscount: false,
      hasPromo: false,
      hasMultiplePromo: false,
      isShaken: false,
      isLoading: false,
      isMakingPayment: false,
      promoCode: '',
      promoCodeApiMsg: '',
      showPromoCodeInput: false,
      isPromoCodeValid: true,
      isPromoCodeApplied: false,
      isPromoCodeLoading: false,
    };
  },
  components: {
    SubscribeForm,
  },
  computed: {
    ...mapState('userData', [
      'usedNewPricing',
    ]),
    extraClass() {
      const cssClass = [];

      if (this.isShaken) cssClass.push('is-shaken');

      return cssClass.join(' ');
    },
    planType() {
      if (this.selectedPlanFrequency === 'annually') {
        return 'Annual Plan';
      }

      return 'Monthly Plan';
    },
    selectedPlan() {
      return `offeo-premium-${this.selectedPlanFrequency}`;
    },
    promoCodeMsg() {
      let msg = this.promoCodeApiMsg;
      if (isEmpty(this.promoCode)) {
        msg = 'Please enter a promo code';
      }
      return msg;
    },
    logoutLink() {
      return `${process.env.VUE_APP_CANVAS_URL}/logout`;
    },
  },
  methods: {
    ...mapMutations([
      'setIsDownloadPreviewOpened',
    ]),
    togglePlan() {
      if (this.selectedPlanFrequency === 'monthly') {
        this.selectedPlanFrequency = 'annually';
      } else if (this.selectedPlanFrequency === 'annually') {
        this.selectedPlanFrequency = 'monthly';
      }

      this.planDetails();
    },
    planDetails() {
      this.isLoading = true;
      const params = {
        plan: this.selectedPlan,
      };
      if (this.promoCode !== '') {
        params.promoCode = this.promoCode;
      }

      Api.getPlanDetails(params)
        .then((response) => {
          if (response.data.success) {
            this.billedToday = showDecimalIfNeeded(response.data.billed_today);
            this.billedAs = showDecimalIfNeeded(response.data.billed_as);
            this.oldAmount = showDecimalIfNeeded(response.data.old_amount);
            this.newAmount = showDecimalIfNeeded(response.data.new_amount);
            this.savings = showDecimalIfNeeded(response.data.savings);
            this.savingsPercentage = response.data.savings_percentage;
            this.hasAffiliateDiscount = response.data.has_affiliate_discount;
            this.hasPromo = response.data.has_promo;
            this.hasMultiplePromo = response.data.has_multiple_promo;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    goToPayment(method) {
      this.paymentMethod = method;

      if (this.step !== 3) {
        this.step = 3;
      }
    },
    goToPlanReview() {
      this.step = 2;
    },
    showPaymentLoading(show) {
      this.isMakingPayment = show;
    },
    updatePromoCode(promoCode) {
      this.promoCode = promoCode;
      this.planDetails();
    },
    closeModal() {
      if (!this.isCloseable || this.step > 2) {
        this.isShaken = true;
        setTimeout(() => {
          this.isShaken = false;
        }, 500);
      } else {
        this.$emit('close-subscribe');
      }
    },
    applyPromoCode() {
      this.isPromoCodeValid = !isEmpty(this.promoCode);

      if (this.isPromoCodeValid) {
        this.isPromoCodeLoading = true;
        const promoCodeParams = {
          plan: this.selectedPlan,
          promoCode: this.promoCode,
        };

        Api.checkPromoCode(promoCodeParams)
          .then((response) => {
            this.isPromoCodeLoading = false;
            this.isPromoCodeApplied = true;
            this.promoCodeApiMsg = response.data.description;
            this.updatePromoCode(this.promoCode);
          }).catch((error) => {
            this.isPromoCodeLoading = false;
            this.isPromoCodeValid = false;
            this.promoCodeApiMsg = error.response.data.message;
            this.updatePromoCode('');
          });
      }
    },
    upgradeNextTime() {
      this.$emit('close-subscribe');
      this.setIsDownloadPreviewOpened(true);
    },
  },
  mounted() {
    this.planDetails();
  },
};
</script>

<style lang="scss">
.slide-vertical-enter-to, .slide-vertical-leave {
   max-height: 50px;
   overflow: hidden;
}

.slide-vertical-enter, .slide-vertical-leave-to {
   overflow: hidden;
   max-height: 0;
}

.slide-vertical-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}
.slide-vertical-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.subscribe {
  @include mobile() {
    align-items: flex-end;

    .canvas-modal__content {
      top: 0;
      max-height: none;
      height: 100%;
    }
  }
}

.subscribe .content {
  text-align: left;
  display: flex;
  justify-content: space-between;

  .subscribe__content {
    width: 40%;
    height: auto;
    min-height: 735px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    font-weight: 500;
    line-height: 1.4;

    @include mobile() {
      width: 100%;
      padding: 35px 20px 20px;
      display: block;
      min-height: auto;
    };

    &.example {
      width: 60%;
      box-shadow: none;
      background: url('~@/assets/images/backgrounds/premium-modal.png');
      background-size: cover;
      background-position: right;
      padding: 0;

      @include mobile() {
        display: none;
      };

      .btn-logout {
        position: absolute;
        top: 10px;
        right: 20px;
        z-index: 2;
        color: $red;
        text-decoration: underline;
      }

      .example__wrapper {
        position: relative;
        top: 30px;
        width: 100%;
        height: 540px;

        .example__background {
          position: absolute;
          height: 540px;
        }

        .example__gif {
          position: absolute;
          width: 165px;
          left: 240px;
          top: 105px;
        }
      }
    }

    p {
      font-weight: 400;
      font-size: 0.875em;
    }

    i.icon-checked {
      margin-right: 10px;
      color: $green;
      font-size: 0.875em;
      font-weight: bold;
    }

    .subscribe__content--step-1,
    .subscribe__content--step-2,
    .subscribe__content--step-3 {
      width: 100%;
    }

    .subscribe__content--step-1 {
      .subscribe__title {
        text-align: left;
      }

      .upgrade__list {
        list-style: none;
        padding: 0;

        .upgrade__item {
          font-size: 0.875em;
          font-weight: bold;
          letter-spacing: -0.7px;
          line-height: 1.2;
          text-transform: capitalize;
          color: $textBlack;
          margin-top: 20px;
        }
      }
    }

    .subscribe__content--step-2 {
      .plan__wrapper {
        width: 100%;
        margin-top: 20px;

        .plan__header,
        .plan__body,
        .plan__footer {
          width: 100%;
          max-width: 400px;
          background: $light;
          border-left: 1px solid $blue;
          border-right: 1px solid $blue;
          padding: 15px 20px;

          .plan__item--wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 0.875em;
            font-weight: 400;

            &:not(:last-child) {
              margin-bottom: 10px;
            }

            &.bold {
              .plan__item--name, .plan__item--price {
                font-weight: 600;
              }
            }

            .plan__item--name {
              width: 170px;
              margin-right: 10px;
              display: flex;
              align-items: flex-start;

              .icon-checked {
                margin-top: 5px;
              }
            }

            .plan__item--price {
              text-align: right;
              font-weight: normal;
            }

            .plan__item--break {
              flex-basis: 100%;
              height: 0;
            }
          }
        }

        .plan__header {
          border-top: 6px solid $blue;
          border-top-left-radius: $componentBorderRadius;
          border-top-right-radius: $componentBorderRadius;

          .plan__header--title {
            font-style: normal;
            font-weight: bold;
            font-size: 1.5em;
            text-align: center;
            margin: 0;
          }
        }

        .plan__body {
          position: relative;

          &::before,
          &::after {
            content: '';
            width: calc(100% - 2px);
            border: 1px solid #E1E4E8;
            position: absolute;
            left: 0;
          }
          &::before {
            top: 0;
          }
          &::after {
            bottom: 0;
          }

          p {
            font-weight: 500;
            margin-top: 0;
          }

          .plan__list {
            list-style: none;
            margin-top: 20px;
            margin-bottom: 0;
            padding: 0;

            .plan__item {
              position: relative;
              margin-bottom: 10px;
            }
          }
        }

        .plan__footer {
          border-bottom: 1px solid $blue;
          border-bottom-left-radius: $componentBorderRadius;
          border-bottom-right-radius: $componentBorderRadius;

          .note {
            top: 0;
            text-align: center;
            display: block;
            margin-bottom: 0;
            color: $timelineGrey;
          }
        }
      }

      .plan__switcher {
        margin-top: 5px;
        text-align: center;

        .btn-switch {
          color: $btnLink;
          text-decoration: underline;
          cursor: pointer;
          font-size: 0.813em;
          font-weight: 400;
        }
      }

      .plan__promo-code {
        margin-top: 20px;
        font-size: 0.813em;
        text-align: center;

        label[for="promo-code"] {
          cursor: pointer;
          display: inline-block;
          text-decoration: underline;
          margin-bottom: 5px;
        }

        .promo-code__wrapper {
          position: relative;

          .promo-code {
            input {
              height: 50px;
            }
          }

          .promo-code__buttons {
            display: flex;
            align-items: center;
            justify-content: center;

            position: absolute;
            top: calc(calc(100% - 30px) / 2);
            right: 15px;

            .btn--text {
              font-size: 1em;
              margin-right: 5px;
            }
          }
        }
      }

      .plan__payment-methods {
        margin-top: 40px;

        @include mobile() {
          margin-top: 20px;
          margin-bottom: 40px;
        };
      }

      .plan-price {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;

        .plan-price__label {
          margin: 0 10px;
        }

        .plan-price__switch {
          display: flex;
          width: 40px;

          .slider.round {
            width: 40px;
            background: $componentBlue;
            display: inline-block;
          }
        }

        .plan-price__savings {
          display: flex;
          font-size: 0.875em;
          position: absolute;
          right: 20px;
          top: 10px;

          span {
            margin-top: 15px;
            margin-left: 5px;
          }
        }
      }
    }

    .subscribe__content--step-3 {
      .btn-back {
        margin-bottom: 20px;
        font-size: 1.125em;
        font-weight: 900;
        cursor: pointer;

        .icon-left-arrow {
          font-size: 1.875em;
          margin-left: -10px;
        }
      }

      .subscribe__footer-payment {
        color: $darkGreen;
        font-weight: 500;
        font-size: 0.750em;
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 30px;

        @include mobile() {
          margin-bottom: 40px;
        };

        .icon-lock {
          font-size: 2em;
          margin-right: 5px;
        }
      }
    }

    .subscribe__title {
      font-size: 1.714em;
      font-weight: bold;
      line-height: 1.2;
      margin-bottom: 10px;
      text-align: center;
    }

    .subscribe__subtitle {
      font-size: 1.188em;
      font-weight: 900;
    }

    .subscribe__text {
      color: $grey;
    }

    .loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .btn-next {
      margin-top: 20px;
      justify-content: space-between;
    }

    .btn-card,
    .btn-paypal {
      width: 100%;
      margin: 10px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .smaller {
      color: $grey3;
      font-size: 0.75em;
      margin-top: 0.5em;

      &.lighter {
        color: $timelineGrey;
      }
    }
  }
}

.t--center {
  text-align: center;
}

.is-hidden {
  display: none;
}

.btn-upgrade-next-time{
  position: absolute;
  right: 10px;
  z-index: 10;
  top: 20px;
  color: $salmon;
  font-size: 12px;
  line-height: 16px;
  text-decoration: underline;
}
</style>
